<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Hasbro Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-12 mb-4">
          <base-input
            v-model="submissionForm.hasbroContractNo"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Hasbro Contract Number"
          />
          <base-input
            v-model="submissionForm.hasbroProjectNo"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Hasbro Project Number"
          />
        </div>
      </div>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-12 mb-4">
          <base-input
            v-model="submissionForm.hasbroAssortmentNo"
            type="text"
            container-class="flex items-center"
            label-class="label-w-46"
            label="Assortment Number"
          />
        </div>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useSubmissionForm from './submissionForm.js';

export default {
    name: 'HasbroDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue'))
    },

    inheritAttrs: false,

    setup () {
        const { submissionForm } = useSubmissionForm();
        return {
            submissionForm
        };
    }
};
</script>
